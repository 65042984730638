$(function () {
    $("#updatesDatepickerFrom").datepicker({ dateFormat: 'yy-mm-dd' });
    $("#updatesDatepickerTo").datepicker({ dateFormat: 'yy-mm-dd' });

    $("#updatesShowButton").click(function () {
        var startdate = $('#updatesDatepickerFrom').datepicker('getDate');
        var enddate = $('#updatesDatepickerTo').datepicker('getDate');

        var startD = $.datepicker.formatDate('yy-mm-dd', startdate)
        var endD = $.datepicker.formatDate('yy-mm-dd', enddate)

        $.ajax({
            url: window.location.href + "SubscriptionUpdates",
            type: "GET",
            data: { startDate: startdate !== null ? startD : "", endDate: enddate !== null ? endD : "" }
        })

            .done(function (result) {
                $("#updatesResult").html(result);
                ko.applyBindings(new SubscriptionUpdates(), document.getElementById("subscriptionupdatesinfinitescroll"));
            });
    });
});

$("#SelectedList").on('change', function () {
    var listId = $('#SelectedList').val();
    sessionStorage.setItem('listId', listId);

    $.ajax({
        url: (location.origin).concat(location.pathname).concat(location.hash).concat("SubscriptionListProducts"),  // url without query string i.e. ?listId=xyz (after edit)
        type: "GET",
        data: { listId: listId }
    })

        .done(function (result) {
            $("#subscriptionListProducts").html(result);
            ko.applyBindings(new SubscriptionListProducts(), document.getElementById("subscriptionlistproducts"));
        });
});

$(document).on('click', '.updateAccordionLabel', function () {
    if ($(this).text().indexOf('▼') > 0) {
        $(this).text($(this).text().replace('▼', '▲'));
        $(this).parent().find(".updateAccordionContent").show(300);
    }
    else {
        $(this).text($(this).text().replace('▲', '▼'));
        $(this).parent().find(".updateAccordionContent").hide(300);
    }
});

$(window).scroll($.throttle(500, function () {
    if (document.getElementById("subscriptionupdatesinfinitescroll") === null)
        return;

    if ($(window).scrollTop() >= $(document).height() - $(window).height() - 100) {
        var viewModel = ko.contextFor(document.getElementById("subscriptionupdatesinfinitescroll")).$data;
        if (viewModel.results().length > 0)
            viewModel.refresh();
    }
}));

$('#LogotypeImageFile').bind('change', function () {
    var sizeKB = this.files[0].size / 1024;
    var maxSizeKB = parseInt($('#LargeFilesizeMax').val());
    if (sizeKB > maxSizeKB) {
        $('#LogotypeImageFileClientValidationMessage').text($('#LargeFilesizeInfoText').val());
        $('#btnSubmit').prop('disabled', true);
    } else {
        $('#LogotypeImageFileClientValidationMessage').text('');
    }
    if ($('#MessageImageFileClientValidationMessage').text() == '' & $('#LogotypeImageFileClientValidationMessage').text() == '') {
        $('#btnSubmit').prop('disabled', false);
    }
});

$('#MessageImageFile').bind('change', function () {
    var sizeKB = this.files[0].size / 1024;
    var maxSizeKB = parseInt($('#LargeFilesizeMax').val());
    if (sizeKB > maxSizeKB) {
        $('#MessageImageFileClientValidationMessage').text($('#LargeFilesizeInfoText').val());
        $('#btnSubmit').prop('disabled', true);
    } else {
        $('#MessageImageFileClientValidationMessage').text('');
    }
    if ($('#MessageImageFileClientValidationMessage').text() == '' & $('#LogotypeImageFileClientValidationMessage').text() == '') {
        $('#btnSubmit').prop('disabled', false);
    }
});

$('#subscriptionusernamefilter').keyup(function () {
    var filtervalue = $(this).val().toLowerCase();
    var userTableRows = $('#abousers').find('tbody').find('tr');
    userTableRows.each(function () {
        var row = $(this).text().toLowerCase();
        if (row.indexOf(filtervalue) >= 0) {
            $(this).show();
        } else {
            $(this).hide();
        }
    });
    var adminTableRows = $('#aboadmins').find('tbody').find('tr');
    adminTableRows.each(function () {
        var row = $(this).text().toLowerCase();
        if (row.indexOf(filtervalue) >= 0) {
            $(this).show();
        } else {
            $(this).hide();
        }
    });
});

function ConfirmBeforeDeleteUser(confirmtext, url) {
    if (window.confirm(confirmtext)) {
        window.location.href = url;
    }
}

// functions used for selecting favourites and adding to/removing from shared lists
function GetProductLists(subscriptionId, productId, productLists) {
    // get lists for this subscription/product; product exists -> checked checkbox
    productLists.removeAll();
    $.getJSON("/api/subscription/list/getproductlists", { subscriptionId: subscriptionId, productId: productId })
        .done(function (result) {
            if (result.data !== null && result.data !== undefined) {
                $.each(result.data, function () {
                    productLists.push(this);
                });
            };
        });
};

function AddRemoveListProduct(subscriptionId, listId, productId, checked) {
    return $.getJSON("/api/subscription/list/addremovelistproduct",
        { subscriptionId: subscriptionId, listId: listId, productId: productId, add: checked });
};

function AddRemoveFavourite(productId, checked) {
    return $.getJSON("/api/subscription/favourite/addremovefavourite",
        { productId: productId, add: checked, subscriptionId: 0, personId: 0 });
};

// Subscription updates, infinite scroll

function SubscriptionUpdates() {

    var that = this;

    that.showLoaderImage = ko.observable(false);
    that.results = ko.observableArray();
    that.totalCount = ko.observable(0);
    that.percentage = ko.observable(0);
    that.dataLoaded = ko.observable(false);


    that.refresh = function () {

        var offset = that.results().length;

        if ((that.results().length < that.totalCount() || that.totalCount() === 0) && that.showLoaderImage() === false) {
            that.showLoaderImage(true);
            $.ajax({
                dataType: "json",
                url: "/api/subscription/updates/getmore",
                async: true,
                data: { subscriptionId: $("#subscriptionupdatesinfinitescroll").data("subscriptionid"), startDate: $("#subscriptionupdatesinfinitescroll").data("startdate"), 
                    endDate: $("#subscriptionupdatesinfinitescroll").data("enddate"), offset: offset, pageSize: $("#subscriptionupdatesinfinitescroll").data("itemsperpage"), lang: window.displayLanguage }
            })
            .done(function (result) {

                if (result.data !== null && result.data !== undefined) {

                    that.results.valueWillMutate();
                    ko.utils.arrayPushAll(that.results, result.data.results);
                    that.results.valueHasMutated();

                    that.totalCount(result.data.totalCount);
                    that.percentage(Math.round(ko.utils.unwrapObservable(that.results).length / result.data.totalCount * 100));
                };

                that.showLoaderImage(false);
                that.dataLoaded(true);
            });
        };
    };

    that.refresh();
};

if (document.getElementById("subscriptionupdatesinfinitescroll") !== null)
    ko.applyBindings(new SubscriptionUpdates(), document.getElementById("subscriptionupdatesinfinitescroll"));

// Subscription list, get all products (pagesize=0)

function SubscriptionListProducts() {

    var that = this;

    that.showLoaderImage = ko.observable(false);
    that.results = ko.observableArray();
    that.totalCount = ko.observable(0);
    that.percentage = ko.observable(0);

    that.selectedProduct = ko.observable();
    that.subscriptionId = ko.observable();
    that.productLists = ko.observableArray();
    that.dataLoaded = ko.observable(false);

    addToList(that, true);
    infiniteScroll(that);
    addToFavourites(that);
    if(!sessionStorage.getItem('listId'))
        sessionStorage.setItem('listId', $('#SelectedList').val());
    else
        $('#SelectedList').val(sessionStorage.getItem('listId'));

    that.refresh = function () {
        var offset = 0;
        var pagesize = $("#subscriptionlistproducts").data("itemsperpage");

        if ((that.results().length < that.totalCount() || that.totalCount() === 0) && that.showLoaderImage() === false && $('#SelectedList').val()) {
            that.showLoaderImage(true);
            $.ajax({
                dataType: "json",
                url: "/api/subscription/list/getproducts",
                async: true,
                data: { listId: $('#SelectedList').val(), offset: offset, pageSize: pagesize, lang: window.displayLanguage }
            })
            .done(function (result) {

                if (result.data !== null && result.data !== undefined) {

                    that.results.valueWillMutate();
                    ko.utils.arrayPushAll(that.results, result.data.results);
                    that.results.valueHasMutated();
                    that.totalCount(result.data.totalCount);
                    that.percentage(Math.round(ko.utils.unwrapObservable(that.results).length / result.data.totalCount * 100));
                };

                that.showLoaderImage(false);

                that.dataLoaded(true);
            });
        };
    };
    window.setTimeout(function () {
        that.refresh();
    }, 1);
};

function SubscriptionFavouritesProducts() {

    var that = this;

    that.showLoaderImage = ko.observable(false);
    that.results = ko.observableArray();
    that.totalCount = ko.observable(0);
    that.currentCount = ko.observable(0);
    that.percentage = ko.observable(0);

    that.selectedProduct = ko.observable();
    that.subscriptionId = ko.observable();
    that.productLists = ko.observableArray();
    that.nrPagesOffset = 0;
    that.dataLoaded = ko.observable(false);

    addToList(that);
    infiniteScroll(that);
    addToFavourites(that, true);

    $("#subscriptionSortOrder").change(function () {
        that.results([]);
        that.nrPagesOffset = 0;
        that.refresh();
    });

    that.refresh = function () {
        var subscriptionId = $("#SubscriptionId").val();
        var personId = $("#PersonId").val();
        var sortOrder = $("#subscriptionSortOrder").val();

        if (personId !== undefined && (that.results().length < that.totalCount() || that.totalCount() === 0) && that.showLoaderImage() === false) {
            that.showLoaderImage(true);
            $.ajax({
                dataType: "json",
                url: "/api/subscription/favourites/get",
                async: true,
                data: { subscriptionId: subscriptionId, personId: personId, nrPagesOffset: that.nrPagesOffset, lang: window.displayLanguage, sortOrder: sortOrder }
            })
            .done(function (result) {

                if (result.data !== null && result.data !== undefined) {

                    that.results.valueWillMutate();
                    ko.utils.arrayPushAll(that.results, result.data.results);
                    that.results.valueHasMutated();

                    that.totalCount(result.data.totalCount);
                    that.percentage(Math.round(ko.utils.unwrapObservable(that.results).length / result.data.totalCount * 100));
                };

                that.showLoaderImage(false);
                that.nrPagesOffset++;
                that.dataLoaded(true);
            });
        };
    };
    window.setTimeout(function () {
        that.refresh();
    }, 1);
};

function SubscriptionPopularProducts() {

    var that = this;

    that.showLoaderImage = ko.observable(false);
    that.results = ko.observableArray();
    that.totalCount = ko.observable(0);
    that.currentCount = ko.observable(0);
    that.percentage = ko.observable(0);

    that.selectedProduct = ko.observable();
    that.subscriptionId = ko.observable();
    that.productLists = ko.observableArray();
    that.nrPagesOffset = 0;
    that.dataLoaded = ko.observable(false);
    that.error = ko.observable(false);

    addToList(that);
    infiniteScroll(that);
    addToFavourites();

    $("#subscriptionSortOrder").change(function () {
        that.results([]);
        that.nrPagesOffset = 0;
        that.refresh();
    });

    that.refresh = function () {
        var subscriptionId = $("#SubscriptionId").val();
        var personId = $("#PersonId").val();

        if ((that.results().length < that.totalCount() || that.totalCount() === 0) && that.showLoaderImage() === false) {
            that.showLoaderImage(true);
            $.ajax({
                dataType: "json",
                url: "/api/subscription/popular/get",
                async: false,
                data: { subscriptionId: subscriptionId, personId: personId, nrPagesOffset: that.nrPagesOffset, lang: window.displayLanguage }
            })
                .done(function (result) {

                    if (result.data !== null && result.data !== undefined && result.data.results !== null) {

                        that.results.valueWillMutate();
                        ko.utils.arrayPushAll(that.results, result.data.results);
                        that.results.valueHasMutated();

                        that.totalCount(result.data.totalCount);
                        that.percentage(Math.round(ko.utils.unwrapObservable(that.results).length / result.data.totalCount * 100));
                        that.nrPagesOffset++;
                        that.dataLoaded(true);
                    } else {
                        that.error(true);
                        that.dataLoaded(false);
                    };

                    that.showLoaderImage(false);
                });
        };
    };
    window.setTimeout(function () {
        that.refresh();
    }, 1);
};
function SubscriptionOurProducts() {

    var that = this;

    that.showLoaderImage = ko.observable(false);
    that.results = ko.observableArray();
    that.totalCount = ko.observable(0);
    that.currentCount = ko.observable(0);
    that.percentage = ko.observable(0);

    that.selectedProduct = ko.observable();
    that.subscriptionId = ko.observable();
    that.productLists = ko.observableArray();
    that.endOfList = false;
    that.currentList = "standards";
    that.nrPagesOffset = 0;
    that.dataLoaded = ko.observable(false);
    that.isLoading = false;

    addToList(that);
    infiniteScroll(that);
    addToFavourites();

    $(document).on("click", "#book-tab", function () {
        $("#standard-tab").addClass("disabledTag");
        $("#standard-tab").parent().addClass("disabledTag");

        that.totalCount(0);
        that.currentCount(0);
        that.percentage(0);
        that.dataLoaded(false);
        that.refresh("books");

    });
    $(document).on("click", "#standard-tab", function () {
        $("#book-tab").addClass("disabledTag");
        $("#book-tab").parent().addClass("disabledTag");
        that.totalCount(0);
        that.currentCount(0);
        that.percentage(0);
        that.dataLoaded(false);
        that.refresh("standards");

    });

    $("#subscriptionSortOrder").change(function () {
        that.results([]);
        that.nrPagesOffset = 0;
        that.endOfList = false;
        that.refresh(that.currentList);
    });

    that.refresh = function (productType) {
        if (that.isLoading) return;
        var subscriptionId = $("#SubscriptionId").val();
        var sortOrder = $("#subscriptionSortOrder").val();

        //check to see if we change tab from standards to books
        if (productType === "books" || productType === "standards") {
            if (that.currentList !== productType) {
                that.results([]);
                that.currentList = productType;
                that.nrPagesOffset = 0;
                that.endOfList = false;
            }
            if (!that.endOfList) {
                that.showLoaderImage(true);

                var offsetSnapshot = that.nrPagesOffset;
                that.isLoading = true;

                $.ajax({
                    dataType: "json",
                    url: "/api/subscription/getmore",
                    async: true,
                    data: {
                        subscriptionId: subscriptionId,
                        productType: productType,
                        nrPagesOffset: that.nrPagesOffset,
                        lang: window.displayLanguage,
                        sortOrder: sortOrder
                    }
                })
                .done(function (result) {
                    var standardTabIsActive = $("#standard-tab").hasClass("active");
                    if ((productType === "standards" && standardTabIsActive || productType === "books" && !standardTabIsActive) &&
                        (!(offsetSnapshot === 0 && that.results().length !== 0) || offsetSnapshot !== 0)) {

                        if (result.data !== null && result.data !== undefined) {

                            that.results.valueWillMutate();
                            ko.utils.arrayPushAll(that.results, result.data.results);
                            that.results.valueHasMutated();

                            if (result.data.results.length === 0 || ko.utils.unwrapObservable(that.results).length === result.data.totalCount)
                                that.endOfList = true;
                            that.totalCount(result.data.totalCount);
                            that.percentage(Math.round(ko.utils.unwrapObservable(that.results).length / result.data.totalCount * 100));
                        }
                        that.nrPagesOffset++;
                    }
                    if (productType === "standards") {
                        $("#book-tab").removeClass("disabledTag");
                        $("#book-tab").parent().removeClass("disabledTag");                    }
                    else {
                        $("#standard-tab").removeClass("disabledTag");
                        $("#standard-tab").parent().removeClass("disabledTag");  

                    }
                    that.dataLoaded(true);
                    that.showLoaderImage(false);
                    that.isLoading = false;
                });
            }
        }
    }

    window.setTimeout(function () {
        that.refresh(that.currentList);
    }, 1);
};

if (document.getElementById("subscriptionfavouriteproducts") !== null)
    ko.applyBindings(new SubscriptionFavouritesProducts(), document.getElementById("subscriptionfavouriteproducts"));

if (document.getElementById("subscriptionpopularproducts") !== null)
    ko.applyBindings(new SubscriptionPopularProducts(), document.getElementById("subscriptionpopularproducts"));

if (document.getElementById("subscriptionourproducts") !== null)
    ko.applyBindings(new SubscriptionOurProducts(), document.getElementById("subscriptionourproducts"));

if (document.getElementById("subscriptionlistproducts") !== null)
    ko.applyBindings(new SubscriptionListProducts(), document.getElementById("subscriptionlistproducts"));

function infiniteScroll(that) {
    $(window).scroll($.throttle(500, function () {
        if ($(window).scrollTop() >= $(document).height() - $(window).height() - 500) {
            if (that.results().length > 0)
                that.refresh(that.currentList);
        }
    }));
}

function addToList(that, refresh) {
    $(document).off("click", ".showListPopUp");
    $(document).on("click", ".showListPopUp", function () {
        // add to/remove from list
        var subscriptionId = $("#SubscriptionId").val();
        var productId = $(this).data("productid");

        that.subscriptionId(subscriptionId);
        that.selectedProduct(productId);

        GetProductLists(subscriptionId, productId, that.productLists);

        var title = this.getAttribute("data-title");

        $('#addremovelistproduct').dialog({
            modal: true,
            transitionMask: false,
            position: { my: "right+50 top+10", at: "bottom", of: $(this) },
            title: title,
            buttons: [
                {
                    text: "OK",
                    click: function () {
                        $(this).dialog("close");
                    }
                }
            ]
        });
    });
    $(document).off("click", "#addremovelistproduct input:checkbox");
    $(document).on("click", "#addremovelistproduct input:checkbox", function () {
        var subscriptionId = that.subscriptionId();
        var productId = that.selectedProduct();
        var listId = $(this).val();
        var checked = $(this).prop('checked');
        AddRemoveListProduct(subscriptionId, listId, productId, checked)
            .done(function () {
                if (!refresh)
                    return;
                // reload the same (updated) list
                that.nrPagesOffset = 0;
                that.results.removeAll();
                that.totalCount(0);
                that.refresh();
            });
    });
}

function addToFavourites(that, refresh) {
    $(document).off("click", ".search-favorite-container input:checkbox");
    $(document).on("click", ".search-favorite-container input:checkbox", function () {
        var productId = $(this).data("productid");
        var checked = $(this).prop('checked');
        AddRemoveFavourite(productId, checked)
            .done(function () {
                if (!refresh)
                    return;
                // reload the same number of favourites that were displayed before removing
                that.currentCount(that.results().length);
                that.results.removeAll();
                that.totalCount(0);
                that.nrPagesOffset = 0;
                that.refresh();
            });
    });
}

function AdminDropdownChange(event, subscriptionId, userIdentifier, isNewSubscription){
    
    var dropdownChanged = event.target.id;
    var isAllHidden = false;
    var choice = $("#" + dropdownChanged).val();
    if(choice === "MainAdmin"){
        var dropdown = $("#setting-dropdown" + 0);
        var current = dropdown.val();
        for(i = 1; current; i++){
            var current = dropdown.val();
            if(current === "MainAdmin" && dropdown.attr('id') !== dropdownChanged){
                dropdown.val("Admin");
            }
            dropdown = $("#setting-dropdown" + i);
            current = dropdown.val();
        }
    }
    if(choice === "HiddenAdmin") {
        var dropdown = $("#setting-dropdown" + 0);
        var current = dropdown.val();
        isAllHidden = true;
        for(i = 1; current; i++){
            var current = dropdown.val();
            if (current !== "HiddenAdmin") {
                isAllHidden = false;
            }
            dropdown = $("#setting-dropdown" + i);
            current = dropdown.val();
        }
    }
    if(!isAllHidden){
        $.get("/abonnemang/anvandare/AdminDropdownChange?subscriptionId=" + subscriptionId + "&userIdentifier=" + userIdentifier + "&isNewSubscription=" + isNewSubscription + "&choice=" + choice)
            .done(function(){
                $("#admin-list-container").load("/abonnemang/subscriptionadmins?id=")
            }); 
    }
    else{
        $("#" + dropdownChanged).val("Admin");
    }
};