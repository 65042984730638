var $window = $(window);
var $html = $(document.documentElement);
var $body;

window.displayLanguage = $("html").attr("lang") === "sv" ? "sv-SE" : "en-US";

var animationSpeed = 0;
if (window.matchMedia('(prefers-reduced-motion: no-preference)').matches) {
    animationSpeed = 250;
}
$.fx.speeds._default = animationSpeed;


// Javascript translations from html objects
var translations = $('#js-translations');

if (translations.length !== 0) {
    var translations = $(translations).children();

    var jsTranslations = {};
    for (var kvp of translations) {
        jsTranslations[kvp.name] = kvp.value;
    };
}

$(function () {
    $body = $(document.body);
    $.ajaxSetup({ cache: false });
    /*    $(".search-box").focus();*/
});

if ($html.hasClass('no-js')) {
    $html.removeClass('no-js');
}

// Hero Search Shadow
$("#heroSearch").focus(function () {
    $(".hero-block-shadow").addClass("active");
});

$("#heroSearch").blur(function () {
    $(".hero-block-shadow").removeClass("active");
});

function showLoadingImage(selector) {
    $(selector).append('<div id="loading-image"><img src="/static/images/ajax_loader.gif" alt="Loading..." /></div>');
}

function hideLoadingImage(selector) {
    $(selector).find('#loading-image').remove();
}

function isJson(item) {
    item = typeof item !== "string"
        ? JSON.stringify(item)
        : item;

    try {
        item = JSON.parse(item);
    } catch (e) {
        return false;
    }

    if (typeof item === "object" && item !== null) {
        return true;
    }

    return false;
}

/* MOBILE MENU */

$(function () {
    $('.mega-menu li:has(ul)').addClass('has-children');
});

$(document).on('click', '#navbar-mobile .has-mega-menu a, .navbar-nav .has-children a', function (event) {
    if (window.innerWidth > 991) {
        return;
    }

    var link = $(this);
    var currentMenu = window.currentMenu ? window.currentMenu : link.parent('li').parent('ul');
    var wrapper = currentMenu.closest('.navbar-nav-wrapper');

    var menu;

    if (link.siblings('.mega-menu').length) {
        var navs = link.siblings('.mega-menu').find('>ul').children().clone();

        menu = $('<ul>').append(navs);
    } else {
        menu = link.siblings('ul').clone();
    }

    var backButton = link
        .clone()
        .addClass('back-button')
        .data('back-to', currentMenu)
        .click(function () {
            var backButton = $(this);
            var skippedBackButtons = backButton.parent().nextUntil(':not(:has(.back-button))').children('a.back-button');

            skippedBackButtons.each(function () {
                $(this).data('back-to').remove();
            });

            var backTo = backButton.data('back-to');

            wrapper.animate({ height: backTo.height() }, function () {
                backTo.animate({ left: 0, marginLeft: 0 });
                window.currentMenu.animate({ left: '100%' }, function () {
                    window.currentMenu.remove();
                    window.currentMenu = backTo;
                });
            });
        });

    var backButtons = currentMenu.find('.back-button').parent().clone(true);

    backButton.addClass('back-button--level-' + backButtons.length);

    var currentLink = $('<a />', {
        href: backButton.attr('href'),
        text: wrapper.data('prefix') + ' ' + backButton.text()
    });

    currentLink.wrapInner('<strong></strong>');

    backButton.attr('href', '#');
    backButton.text(wrapper.data('back'));

    menu.prepend($('<li>').append(currentLink));
    menu.prepend($('<li>').append(backButton));

    menu.addClass('nav navbar-nav menu-clone').appendTo(wrapper);

    if (menu.length === 0) {
        return;
    }

    var slideOut = function (then) {
        if (currentMenu.is('.menu-clone')) {
            currentMenu.animate({ left: '-100%' }, then);
        } else {
            currentMenu.animate({ marginLeft: '-100%' }, then);
        }
    }
    var resize = function (then) {
        wrapper.animate({ height: menu.height() }, then);
    }
    var slideIn = function (then) {
        menu.animate({ left: 0 }, then);
    }

    resize(function () {
        slideOut();
        slideIn();
    });

    window.currentMenu = menu;

    event.preventDefault();
});


/* HOVER INTENT ON MEGA MENU */
$(function () {

    $("li.has-mega-menu").hoverIntent({
        over: showMegaMenu,
        timeout: 350,
        out: hideMegaMenu
    });

    function showMegaMenu() {
        $(this).parent().find('li.has-mega-menu').removeClass('hover');
        $(this).addClass('hover');
    }
    function hideMegaMenu() { $(this).removeClass('hover'); }

});


/* SAME HEIGHT ON IMAGE TEASERS */

$(function () {
    var groups = {};

    $('[same-height-as]').each(function () {
        var element = $(this);
        var value = element.attr('same-height-as');

        if (!(value in groups)) {
            groups[value] = [];
        }

        groups[value].push(element);
    });

    function update() {
        $.each(groups, function (selector, elements) {
            var maxHeight = 0;

            $.each(elements, function (i, element) {
                element.css({ paddingTop: '', paddingBottom: '' });
            });

            if (elements.length > 1) {
                $.each(elements, function (i, element) {
                    maxHeight = Math.max(element.outerHeight(), maxHeight);
                });
            } else {
                $(selector).each(function () {
                    maxHeight = Math.max($(this).outerHeight(), maxHeight);
                });
            }

            $.each(elements, function (i, element) {
                var height = element.height();
                var neededPadding = (maxHeight - height) / 2;
                element.css({ paddingTop: neededPadding + 'px', paddingBottom: neededPadding + 'px' });
            });
        });
    }
    if ($('[same-height-as]').length > 0) {
        update();
        $(window).resize($.throttle(100, update));
    }
});

/* CAROUSEL */
$(function () {

    var nextTitle = "Nästa";
    var prevTitle = "Förra";

    if (window.displayLanguage === "en-US") {
        prevTitle = "Previous";
        nextTitle = "Next";
    }

    $('.carousel').slick({
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 1,
        prevArrow: '<a title="' + prevTitle + '" href="#" class="slick-prev"></a>',
        nextArrow: '<a title="' + nextTitle + '" href="#" class="slick-next"></a>',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    });

});

/* HAS ATTRIBUTE */
$.fn.hasAttr = function (attrName) {
    var element = this[0];

    if (element.hasAttribute) {
        return element.hasAttribute(attrName);
    }

    return element[attrName] !== undefined;
}

/* ADD ATTRIBUTE */
$.fn.addAttr = function (attrName) {
    this.attr(attrName, '');
};

/* VIEW SWITCHER */
$.fn.resetViews = function () {
    var pane = this;

    if (!pane.hasAttr('initial-view')) {
        return;
    }

    pane
        .attr('current-view', pane.attr('initial-view'))
        .removeAttr('initial-view')
        .removeAttr('history');

    pane.children().removeAttr('previous-view').css({ top: '', display: '' });
};

$.fn.showView = function (nextViewTarget) {
    var pane = this;

    if (this.length === 0) {
        return;
    }

    var currentViewTarget = pane.attr('current-view');
    var currentView = pane.children(currentViewTarget);
    var nextView = pane.children(nextViewTarget);

    if (!pane.hasAttr('initial-view')) {
        pane.height(pane.height());
        pane.attr('initial-view', currentViewTarget);
    }

    if (pane.height() === 0) {
        pane.trigger($.Event('show.sis.pane'));
    }

    if (currentViewTarget === nextViewTarget) {
        pane.animate({ height: currentView.outerHeight() });
        return;
    }

    pane.attr('current-view', nextViewTarget);

    var history = pane.hasAttr('history') ? pane.attr('history').split(' ') : [];

    if (nextView.hasAttr('previous-view')) {
        nextView.removeAttr('previous-view');

        var viewsToReset = history.slice($.inArray(nextViewTarget, history) + 1);

        $.each(viewsToReset, function (i, viewToReset) {
            pane.children(viewToReset).removeAttr('previous-view').css({ left: '', marginLeft: '' });
        });

        var newHeight = nextView.outerHeight();

        nextView.css({ top: -newHeight }).show();

        pane.animate({ height: newHeight });
        currentView.animate({ top: newHeight }, function () {
            currentView.hide();
        });
        nextView.animate({ top: 0 }, function () {
            nextView.find('input').first().focus();
        });

        return;
    }

    history.push(currentViewTarget);

    pane.attr('history', history.join(' '));

    currentView.addAttr('previous-view');

    var currentHeight = currentView.outerHeight()
    var newHeight = nextView.outerHeight();

    nextView.css({ top: currentHeight }).show();

    pane.animate({ height: newHeight });
    currentView.animate({ top: -currentHeight }, function () {
        currentView.hide();
    });
    nextView.animate({ top: 0 }, function () {
        nextView.find('input').first().focus();
    });
};

$(document).on('click', '[view-switcher] [switch-to]', function (event) {
    var button = $(this);
    var pane = button.closest('[view-switcher]');

    pane.showView(button.attr('switch-to'));
});

$(document).on('click', '[view-switcher] [cancel]', function (event) {
    var button = $(this);
    var pane = button.closest('[view-switcher]');

    pane.animate({ height: 0 }, function () {
        pane.resetViews();
    });
});

$(document).on('click', '[show-view]', function (event) {
    event.preventDefault();

    var button = $(this);
    var targetAttr = button.attr('show-view');
    var pane = $(targetAttr).closest('[view-switcher]');
    var target = targetAttr.split(/[ >]+/).pop();

    pane.showView(target);
});

$(function () {
    $('[view-switcher]').each(function () {
        var pane = $(this);
        $(window).resize($.throttle(100, function () {
            if (pane.height() === 0) {
                return;
            }

            var currentViewTarget = pane.attr('current-view');
            var currentView = pane.children(currentViewTarget);

            var previousViews = pane.children('[previous-view]');

            var height = currentView.outerHeight();

            pane.height(height);
        }));
    });
});

/* TOGGLE HIDDEN COLLAPSE CLOSE BUTTONS */
var collapseCloseButtonsToToggle = $();

$(document).on('show.bs.collapse', function (event) {
    var target = $(event.target).attr('id');

    if (target === "my-sis-pane") {
        var ocViewModel = ko.contextFor(document.getElementById("order-container")).$data;
        if (ocViewModel !== null && ocViewModel !== undefined)
            ocViewModel.GetOrders();

        var stdViewModel = ko.contextFor(document.getElementById("my-standards")).$data;
        if (stdViewModel !== null && stdViewModel !== undefined)
            stdViewModel.GetStandards();

        var msViewModel = ko.contextFor(document.getElementById("my-services-block")).$data;
        if (msViewModel !== null && msViewModel !== undefined)
            msViewModel.GetServices();
    }

    if (collapseCloseButtonsToToggle.length) {
        collapseCloseButtonsToToggle.show();
    } else {
        collapseCloseButtonsToToggle = $('[data-toggle="collapse"][data-target="#' + target + '"].hidden').removeClass('hidden');
    }
});

$(document).on('hide.bs.collapse', function (event) {
    collapseCloseButtonsToToggle.hide();
});

/* DON'T SHOW MOBILE MENU AND SHOPPING CART SIMULTANEOUSLY */
function cartMobileMenuSimultaneously(x) {
    if (x.matches) { // If media query matches
        $(document).on('show.bs.collapse', '#navbar-collapse', function (event) {
            var shoppingCart = $('#shopping-cart');
            if (shoppingCart.is(':visible')) {
                shoppingCart.collapse('hide');
            }
        });

        $(document).on('show.bs.collapse', '#shopping-cart', function (event) {
            var mainMenu = $('#navbar-collapse');
            if (mainMenu.is(':visible')) {
                mainMenu.collapse('hide');
            }
        });
    }
}

var x = window.matchMedia("(max-width: 700px)")
cartMobileMenuSimultaneously(x) // Call listener function at run time
x.addListener(cartMobileMenuSimultaneously) // Attach listener function on state changes

// Only show one open hero link list simutaneously
if (x.matches) {
    var $heroListBlockSimultaneously = $('.hero-list-block');
    $heroListBlockSimultaneously.on('click', function () {
        $heroListBlockSimultaneously.find('.collapse.in').collapse('hide');
    });
}

/* EXPANDABLE PANELS */
$(document).on('click', '.panel--expandable .panel-heading', function (event) {
    var heading = $(this);
    var panel = heading.closest('.panel');

    panel.toggleClass('panel--expanded');
});


/* EXPANDABLE LIST GROUP ITEMS */
$(document).on('click', '.list-group-item--expandable .list-group-item--expandable-head', function (event) {
    var heading = $(this);
    var panel = heading.closest('.list-group-item');

    panel.toggleClass('list-group-item--expanded');
});

/* POPOVERS */
$(function () {
    setTimeout(function () {
        $('[data-toggle="popover"]').popover({ placement: 'left' });
    }, 1000);

});

/* DATE PICKER */
$(function () {
    $('.datepicker').datepicker();
    $('.datepicker-button').click(function () { $(this).siblings('.datepicker').focus(); });
});

/* SYSTEM MESSAGE */
$(function () {
    if (Cookies.get('systembar') === 'hide' || $('.systembar').length) {
        return;
    }

    var systembar = $('<div class="systembar" style="display:block;">');

    $('<span>')
        .append(window.systemMessage)
        .appendTo(systembar);

    $('<a href="#">')
        .addClass('button')
        .click(function () {
            $('.systembar').hide();
            Cookies.set('systembar', 'hide', { path: '/', secure: false, domain: '' })
        })
        .append(window.systembarButton)
        .appendTo(systembar);

    systembar.prependTo(document.body);
});

/* DELETE COURSE ATTENDANT EFFECT */
$(document).on('click', '.book-course-table-remove', function () {
    if (!confirm('Är du säker på att du vill ta bort deltagaren?')) {
        return;
    }

    var button = $(this);
    var item = button.closest('tr');

    item.animate({ opacity: 0 }, function () {
        setTimeout(function () { item.remove(); }, 500);
    });
});


/* EXPAND ORDER ITEMS */
$(document).on('click', '.order-item', function () {
    var element = $(this);

    var isExpanded = element.hasClass('order-item--expanded');
    var shouldExpand = !isExpanded;

    element.toggleClass('order-item--expanded', shouldExpand);

    var orderDetail = element.next('.order-detail');
    var orderDetailInner = orderDetail.find('.order-detail-inner');

    if (shouldExpand) {
        orderDetailInner.hide().slideDown();
    }
});


/* EXPANDABLE AREAS */
$(document).on('click', '.expand-area, .contract-area', function () {
    var element = $(this);

    var otherElement = element.prev('.expand-area, .contract-area');
    if (!otherElement.length) {
        otherElement = element.next('.expand-area, .contract-area');
    }

    var listGroup;
    if (element.prev().is('.expand-area, .contract-area')) {
        listGroup = element.prev().prev();
    } else {
        listGroup = element.prev();
    }

    if (element.is('.expand-area')) {
        listGroup.show()
        element.hide();
        otherElement.css('display', 'block');
    } else {
        listGroup.hide();
        element.hide();
        otherElement.css('display', 'block');
    }
});


/* GO TO TOP */
$(function () {
    var goToTop = $('.go-to-top');

    if (goToTop.length) {
        goToTop.click(function (event) {
            event.preventDefault();

            $('html, body').animate({ scrollTop: 0 });
        });

        var isShowing = false;


        $window.scroll($.throttle(500, function (event) {
            if ($window.scrollTop() > 100) {
                if (!isShowing) {
                    isShowing = true;
                    goToTop.animate({ marginBottom: 0 });
                }
            } else {
                if (isShowing) {
                    isShowing = false;
                    goToTop.animate({ marginBottom: -100 });
                }
            }


            var fullHeight = $(window).scrollTop() + $(window).height();
            if (fullHeight >= $(document).height() - 70) {
                goToTop.addClass("bottom");
            } else {
                goToTop.removeClass("bottom");
            }
        }));
    }
});


// Hero height
if ($("#hero-block") !== null) {
    var navbarH = $("#navbar").height();
    $("#hero-block").css('min-height', "calc(100vh - " + navbarH + "px)");
}

// vh unit fix for EPiServer editor.
(function () {
    if (window.top.epi !== undefined && window.top.epi.shell !== undefined) {
        var head = document.getElementsByTagName('head')[0];
        var s = document.createElement('style');
        s.appendChild(document.createTextNode("#hero-block { min-height: unset!important; padding:150px 0px; }"));
        head.appendChild(s);
    }
}());

$(document).on('click', 'a[href="#"]', function (event) { event.preventDefault(); });

$(document).on('submit', '#userinformation-form', function (e) {
    e.preventDefault();

    var data = $(e.target).serialize();
    var action = e.target.action;
    $.ajax({
        type: "POST",
        url: action,
        data: data,
        success: function (r) {
            $(e.target).html(r);
            $('.TypeOfMembership').on('change', function (e) {
                $('.company-fields').toggle();
            });
        }
    });
});

$('#offerModal').on('show.bs.modal', function (e) {
    // Hide any status messages
    $('#js-offer-form-success-message').addClass("d-none");
    $('#js-offer-form-error-message').addClass("d-none");

    // Show the form
    $('#offer-form').removeClass("d-none");
});

$(document).on('submit', '#offer-form', function (e) {
    e.preventDefault();

    // Clear error messages
    $('.validation-message').each(function () { $(this).text(''); });

    var data = $(e.target).serialize();
    var action = e.target.action;
    $.ajax({
        type: "POST",
        url: action,
        data: data,
        success: function (result) {
            if (result.success) {
                // Restore modal if we open it again
                $(':input', '#offer-form')
                    .not(':button, :submit, :reset, :hidden')
                    .val('');

                // Hide the form
                $(e.target).addClass("d-none");

                // Hide any error message
                $('#js-offer-form-error-message').addClass("d-none");

                // Show the success message
                $('#js-offer-form-success-message').removeClass("d-none");
            }
        else {
            $.each(result.errors, function () {
                var element = $("[data-valmsg-for=" + this.key + "]");
                element.html(this.errors.join());
                element.removeClass("field-validation-valid");
            });
        }
        },
        error: function (result) {
            // Hide any success message
            $('#js-offer-form-success-message').addClass("d-none");

            // Show the error message
            $('#js-offer-form-error-message').removeClass("d-none");
        }
    });
});

// Populates the offer modal when user clicks "Ask for quote" in search results
function PopulateOfferModal(title, productId, edition, languageCodes) {
    // Set visible elements
    $('#offer-modal-summary #ProductTitle').text(title);
    $('#offer-modal-summary #ProductEdition').text(edition);

    let languageContainer = $('#offer-modal-summary #ProductLanguageContainer');

    // Remove all flags if modal has been opened before
    languageContainer.find('span.icon_lang_text').remove();

    // Adds the new flags into the language container
    languageCodes.forEach(function (element, index) {
        let span = document.createElement("span")
        span.className = "icon_lang_text " + element;
        languageContainer.append(span);
    });

    // Set hidden input form elements
    $('#offer-form #ProductTitle').val(title);
    $('#offer-form #ProductId').val(productId);
    $('#offer-form #ProductEdition').val(edition);

    // Before we add the language codes to the hidden form field, 
    // we remove the "icon_lang_" prefix to make it more human readable
    $('#offer-form #ProductLanguages').val(languageCodes.join(',').replaceAll("icon_lang_", ""));
}

$(function () {
    $(document).on('click', '.suggest-abo-add', function () {
        ShowAboAddDialog();
    });
    $(document).on('click', '.suggest-abo-add-search', function () {
        currentProduct = $(this).data('productid');
        ShowAboAddDialog($(this).data('designation'));
    });
    function ShowAboAddDialog(designation) {
        $("#suggest_abo_designation").html(designation);
        $("#suggest-abo-add-dialog").dialog({
            height: "auto"
            , autoOpen: true
            , closeOnEsc: true
            , modal: true
            , width: 600
        });
    }
});

function sendExtendSubscriptionEmail() {
    event.preventDefault();
    var name = $('#subscriptionFormName').text()
    if (name === "") {
        name = $('#subscriptionFormNametxt').val()
    }
    var email = $('#subscriptionFormEmail').text()
    if (email === "") {
        email = $('#subscriptionFormEmailtxt').val()
    }
    if (email === "" || name === "") {
        $('#suggest-abo-add-dialog-required-field-missing').show();
        return;
    } else {
        $('#suggest-abo-add-dialog-required-field-missing').hide();
    }

    var admin = $('input[name=adminGroup]:checked').val();
    var comment = $('#subscriptionFormComment').val();
    $.ajax({
        type: "POST",
        url: '/api/extendSubscription/SendForm?productId=' + currentProduct + '&admin=' + admin + '&user=' + name + '&userEmail=' + email + "&comment=" + comment,
        success: function (data) {
            if (data.data == "Ok") {
                $(".ui-button-icon").attr("onClick", "$('#suggest-abo-add-dialog-sent').attr('style','display:none');$('#suggest-abo-add-dialog').dialog('close');$('#suggest-abo-add-dialog-form').show();");
                $("#suggest-abo-add-dialog-form").hide();
                $('#suggest-abo-add-dialog-error').hide();
                $('#suggest-abo-add-dialog-sent').show();
            }
            else {
                $('#suggest-abo-add-dialog-error').show();
            }
        },
        error: function (request, status, error) {
            console.log(":-(");
        }
    });
}

function sendOfferEmail(productId, subscriptionId) {
    event.preventDefault();
    $.ajax({
        type: "POST",
        url: '/api/offer/sendoffer?productId=' + productId + '&subscriptionId=' + subscriptionId + "&other=" + $('#otherText').val(),
        success: function (data) {
            $('#offertForm').hide();
            $('#formSent').show();
        },
        error: function (request, status, error) {
            console.log(":-(");
        }
    });
}

/* Input file */
var inputs = document.querySelectorAll('.inputfile');
Array.prototype.forEach.call(inputs, function (input) {
    var label = input.nextElementSibling,
        labelVal = label.innerHTML;

    input.addEventListener('change', function (e) {
        var fileName = '';
        if (this.files && this.files.length > 1){
            fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);
        }
        else{
            fileName = e.target.value.split('\\').pop();
        }

        if (fileName)
            label.querySelector('span').innerHTML = fileName;
        else
            label.innerHTML = labelVal;
    });
});

$('#UserFile').change(function (e) {
    var submitButton = $('#addFile > #btnSubmit');
    if(this.files.length > 0){
        submitButton.removeAttr('disabled');
        submitButton.removeClass("btn-greyed-out");
        submitButton.addClass("btn-secondary");
    }
} )

$('#icsFavorites input').click(function (e) {

    $("#icsFavorites").css("opacity", 0.5);

    var value = $(this).val();
    var splitArray = value.split('|');
    var code = splitArray[0];
    var publisher = splitArray[1];

    var updatedCodes = code;

    // update all children to same "checked" status
    // NOTE! AddRemoveFavouriteIcs method TOGGLES values, only find values that need changing

    var subscribedIcsList = $("#subscribedIcsList").val().split(";");

    var thisChecked = $(this).prop('checked');

    for (var i in subscribedIcsList) {
        var ics = subscribedIcsList[i];
        // only check subcategories of the clicked code
        if (ics.startsWith(code)) {
            var testVal = ics + "|" + publisher;
            var testChecked = $('input[type=checkbox][value="' + testVal + '"]').prop('checked');
            if (testChecked === thisChecked) {
                // the subcat already matches checked/unchecked status of clicked code
                // do nothing
            } else {
                // set value of the subcategory to same as thisChecked (TOGGLE)
                $('input[type=checkbox][value="' + testVal + '"]').prop('checked', thisChecked);
                // and make sure this change will be saved to favourites
                updatedCodes += ";" + ics;
            }
        }
    }

    AddRemoveFavouriteIcs(updatedCodes, publisher);
});

function AddRemoveFavouriteIcs(codes, publisher) {
    return $.getJSON("/api/subscription/favourite/addremovefavouriteICS",
        { codes: codes, publisher: publisher })
        .done(function (result) {
            $("#icsFavorites").css("opacity", 1);
        });
};

$('#icsNotificationPreferences input[type="radio"][name="icsPref"]').click(function (e) {
    var value = $(this).val();

    return $.getJSON("/api/subscription/favourite/UpdateMailNotificationPreferences",
        { preference: value });
});

$(function () {
    if ($("#icsSelectorDiv").length > 0) {

        $(document).on("change", "input[name=icsPref]", function () {
            if ($(this).val() === '1') {
                $("#icsSelectorDiv").show(250);
            } else {
                $("#icsSelectorDiv").hide(250);
            }
        });

        var selector = $('input[name=icsPref]:checked');
        if (selector.val() === '1') {
            $("#icsSelectorDiv").show(250);
        } else {
            $("#icsSelectorDiv").hide(250);
        }
    }
});


$(document).on('click', '#icsFilter input', function (e) {
    var code = $(this).val();
    // update all children (which have value STARTING with code) to same "checked" status
    var thisChecked = $(this).prop('checked');
    $('input[type=checkbox][name="ics"][value^="' + code + '"]').prop('checked', thisChecked);
});

/* Active Menu and admin styling*/
$(function () {

    // Add styling for search container
    function toolboxAdminStyle() {
        $('.toolbox').addClass('admin--loggedin');
    }

    // Add active classes to abo menu tab
    function aboMenuActive() {
        $('.nav.navbar-nav li').each(function () {
            if (!$(this).hasClass('has-mega-menu')) {
                $(this).addClass('active admin--loggedin');
            }
        });
    }

    var path = window.location.pathname;
    path = path.replace(/\/$/, "/");
    path = decodeURIComponent(path);

    // Add tab color to current location
    $(".nav.navbar-nav a").each(function () {
        var href = $(this).attr('href');
        if (path.substring(0, href.length) === href) {
            $(this).closest('.nav.navbar-nav > li').addClass('active');
        }
    });

    // Input is only displayed when the user is logged in and on search page - Style search bar and abo tab
    if ($("input[name='showOnlyInSubscription']").length) {
        toolboxAdminStyle();
        aboMenuActive();
    }

    if (typeof currentProduct !== "undefined") {
        // Check if there's a current product and add admin styling
        if (currentProduct && currentProduct.length > 0 && $("#mysismenu").length) {
            toolboxAdminStyle();
            aboMenuActive();
        }
    }

    if (typeof bookProduct !== "undefined") {
        // Check if there's a current handbookproduct and add admin styling
        if (bookProduct && bookProduct.length > 0 && $("#mysismenu").length) {
            toolboxAdminStyle();
            aboMenuActive();
        }
    }
});

function heroListPlacement() {
    if ($(".heroredesignblock").height() - (parseFloat($("#hero-block").css('padding-top'))) - $(".hero-content").height() > 200) {
        $(".hero-list-block").css({ 'margin-top': '-200px', 'opacity': '1' });
    } else {
        $(".hero-list-block").css({ 'opacity': '1', 'margin-top': '40px' });
    }
}

// Enable tooltips
$('[data-toggle="tooltip"]').tooltip();
$(function () {
    if ($("#hero-block").length > 0) {
        heroListPlacement();
        // Hero-list-block margin
        $window.resize($.throttle(100, heroListPlacement));
    }
});

function heroScale() {
    $(".hero .hero-image img").css("min-height", $(".hero .hero-text").height() + 150 + "px");
}

$(function () {
    if ($(".hero .hero-text").length > 0) {
        heroScale();
        $window.resize($.throttle(100, heroScale));
    }
});

if ($(".img-teaser").length > 0) {
    // Image teaser min height col 2-3
    var imgTB12 = $(".img-teaser.col-sm-12");
    var imgTB8 = $(".img-teaser.col-sm-8");

    function imgTeaserH(myTeaserBlock) {
        for (var i = 0; i < myTeaserBlock.length; i++) {
            var textH = myTeaserBlock[i].getElementsByClassName("text-main")[0].scrollHeight;
            if (textH > 320) { //320 is min height of block
                $(myTeaserBlock[i]).css("min-height", 50 + textH + "px");
            }
        }
    }

    imgTeaserH(imgTB12);
    imgTeaserH(imgTB8);
}

//check that gdpr and captcha is checked before enabling send button on demotryoutform/subscriptionaddpageform

var GdprCheckbox = false;
var Recaptcha = false;
var ContractCheckbox = false;
var SwedishCheckbox = false;

if ($('#gdpr-checkbox').is(':checked')) {
    $('#gdpr-checkbox').removeAttr('checked');
}

if ($('#contract-checkbox').is(':checked')) {
    $('#contract-checkbox').removeAttr('checked');
}

if ($('#swedish-checkbox').is(':checked')) {
    $('#swedish-checkbox').removeAttr('checked');
}

$("#gdpr-checkbox").change(function (e) {
    GdprCheckbox = !GdprCheckbox;
    toggleSubmitBtn();
});

$("#contract-checkbox").change(function (e) {
    ContractCheckbox = !ContractCheckbox;
    toggleSubmitBtn();
});

$("#swedish-checkbox").change(function (e) {
    SwedishCheckbox = !SwedishCheckbox;
    toggleSubmitBtn();
});

function recaptchaCallback() {
    Recaptcha = !Recaptcha;
    toggleSubmitBtn();
};

function recaptchaExpired() {
    Recaptcha = !Recaptcha;
    toggleSubmitBtn();
};

function toggleSubmitBtn(element) {

    var attr = $('#btnSubmit').attr('disabled');

    //if it is subscriptionaddpage also check contract and swedish checkbox

    var contractAndSwedishChecked = true;
    if ($('#contract-checkbox').length) {
        if (!ContractCheckbox || !(SwedishCheckbox === $('#swedish-checkbox').length > 0)) {
            contractAndSwedishChecked = false;
        }
    }

    if (Recaptcha && GdprCheckbox && contractAndSwedishChecked && (typeof attr !== typeof undefined && attr !== false)) {
        $('#btnSubmit').removeAttr('disabled');
    }
    else if ((!Recaptcha || !GdprCheckbox || !contractAndSwedishChecked) && (typeof attr === typeof undefined || attr === false)) {
        $('#btnSubmit').addAttr('disabled');
    }
}


// Checks if element is visible on sceen, percentage is a decimal between 0 to 1 and used to say if the element needs to just enter (0) or be fully visible (1) or somewhere between (0.5)
$.fn.isOnScreen = function (percentage) {
    var win = $(window);

    var viewport = {
        top: win.scrollTop(),
        left: win.scrollLeft()
    };
    viewport.right = viewport.left + win.width();
    viewport.bottom = viewport.top + win.height();

    var bounds = this.offset();
    bounds.right = bounds.left + this.outerWidth();
    bounds.bottom = bounds.top + this.outerHeight();

    return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top + (this.outerHeight() * percentage) || viewport.top > bounds.bottom));

};

// Tracks impressions for teasers
$(function () {
    var teaserImpressions = [];
    var $teasers = $(".block .teaser");

    function sendTeaserImpressions() {
        var index = 0;
        var unsentImpressions = [];

        for (var i = 0; i < $teasers.length; i++) {
            var $teaser = $($teasers[i]);
            index++;
            if ($teaser.isOnScreen(0.5) && teaserImpressions.indexOf(index) < 0) {
                teaserImpressions.push(index);

                unsentImpressions.push({
                    'id': $teaser.data("id"),
                    'name': $teaser.data("name"),
                    'creative': $teaser.data("creative"),
                    'position': index
                });
            }
        }

        if (unsentImpressions.length > 0) {
            new Countquest().promoViews(unsentImpressions);
        }
    }

    if ($teasers.length > 0) {
        $window.scroll($.throttle(1000, sendTeaserImpressions));
    }

    sendTeaserImpressions();
});

// Track promo clicks
$(function () {
    $(".teaser").on("click", function () {
        new Countquest().promoClick($(this).data("id"), $(this).data("name"), $(this).data("creative"), this);
    });
});

// Tracks impressions for products in search results
var searchResultImpressions = [];
function sendSearchResultImpressions() {
    var $results = $(".search-result[data-productid]");

    var unsentImpressions = [];

    for (var i = 0; i < $results.length; i++) {
        var $result = $($results[i]);
        if ($result.isOnScreen(0.5) && searchResultImpressions.indexOf($result.data("productid")) < 0) {
            searchResultImpressions.push($result.data("productid"));

            unsentImpressions.push({
                'id': $result.data("productid"),
                'name': $result.data("name"),
                'price': $result.data("price"),
                'category': $result.data("category"),
                'list': "Search results page",
                'position': $result.data("position")
            });
        }
    }

    if (unsentImpressions.length > 0) {
        new Countquest().productImpressions(unsentImpressions);
    }
}

$(function () {
    $(".tablesorter").each(function (index, e) {
        new Tablesort(e);
    });

});

$(function () {
    if ($(".searchPage").length > 0) {
        $window.scroll($.throttle(1000, sendSearchResultImpressions));
    }
});

function toggleLanguage(event) {
    event.preventDefault();
    window.location.href = event.target.href + location.search + location.hash;
}

// Menu toggle
$(document).on('click', '.menu-toggle', function (e) {
    $(this).closest("li").toggleClass("expanded");
    e.preventDefault();
});

function StopLoad(target) {
    setTimeout(function () {
        $(target).parent(".loadingIframe").removeClass("loadingIframe")
        $(target).css("opacity", "1");
    }, 1500);
}
$(function () {
    $(".LightBoxPdf").on("click", function (e) {
        e.stopPropagation();
        e.stopImmediatePropagation();

        var url = $(e.target).attr("href");
        if (url == null || url == undefined) {
            url = $(e.target).parent().attr("href");
        }
        if (url.includes("sis.se")) {
            url = "/api" + url.split("/api");
        }
        e.preventDefault();

        $.ajax({
            type: "GET",
            data: { pdfurl: url },
            url: '/Overlay/ShowOverlay',
            contentType: "html",
            success: function (result) {
                $('body').append(result)
            }
        });
    });
    $(".lightBoxLink").on("click", function (e) {
        e.stopPropagation();
        e.stopImmediatePropagation();

        $(e.target).nextAll(".lightbox-modal").first().css("display", "");
        
        $(".embed-responsive:nth-child(2)").closest(".embed-responsive").removeClass("embed-responsive");

    });
    $(".lightBoxLinkImg").on("click", function (e) {
        e.stopPropagation();
        e.stopImmediatePropagation();

        $(e.target).parent().parent().next(".lightbox-modal").css("display", "");

        $(".embed-responsive:nth-child(2)").closest(".embed-responsive").removeClass("embed-responsive");

    });
});
$(document).on('keydown', function (e) {
    if (e.keyCode === 27) { // ESC
        $(".lightbox-modal").hide();
    }
});
// Lightbox
$(function () {
    $(".lightbox").after("<span class='lightbox-icon'></span>");

    $(".lightbox").on("click", function (e) {
        var img = $(this).data("lightbox");
        var text = $(this).data("lightboxtext");
        var download = $(this).data("download");

        var modal = "<div class='lightbox-modal'><div class='lightbox-content'><button class='lightbox-close'>x</button><img src='" + img + "' class='lightbox-image' />";
        if (text || download) modal += "<div class='lightbox-text py-2'>" + text + "</div>";
        if (download) modal += "<a href='" + download + "' download target='_blank' class='lightbox-link'>Ladda ner</a>";
        modal += "</div></div>";
        $("body").append(modal);
    });


    $(document).on("click", ".lightbox-icon", function (e) {
        if (!$(e.target).hasClass("blockLightbox")) {
            $(this).prev(".lightbox").click();
        }
    });

    $(document).on("click", ".lightbox-close, .lightbox-modal", function (e) {
        if ($(e.target).hasClass("blockLightbox")) {
            $(e.target).closest(".lightbox-modal").css("display", "none");
        }
        else {
            if ($(e.target).hasClass("lightbox-modal") || $(e.target).hasClass("lightbox-close")) {
                $(e.target).closest(".lightbox-modal").remove();
            }
        }
    });
});


$(document).on('click', '.toggle', function (e) {
    e.preventDefault();

    var $this = $(this);

    if ($this.next().hasClass('show')) {
        $this.next().removeClass('show');
        $this.next().hide();
        $(this).find('i').removeClass('fa-chevron-down').addClass('fa-chevron-right');
    }

    else {
        $this.parent().parent().find('li .inner').removeClass('show');
        $this.parent().parent().find('li .inner').hide();
        $this.parent().parent().find('li').find('i').removeClass('fa-chevron-down').addClass('fa-chevron-right');
        $this.next().toggleClass('show');
        $this.next().show();
        $(this).find('i').removeClass('fa-chevron-right').addClass('fa-chevron-down');
    }
});


function getUrlParameter(name) {
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search) || [, ""])[1].replace(/\+/g, '%20')) || null;
}

function changeUrlParam(param, values) {
    var currentUrl = "";
    var change = "";
    if (window.location.href.indexOf("?") == -1) {
        currentUrl = window.location.href + '?';
    }
    else {
        currentUrl = window.location.href + '&';
        change = param + "=" + new URL(location.href).searchParams.get(param) + '&';
    }

    var newUrl = currentUrl.replace(change, '');

    if (values !== null) {
        if ($.isArray(values)) {
            $.each(values, function (index, value) {
                newUrl = newUrl + param + "=" + value + "&";
            });
        }
        else {
            newUrl = newUrl + param + "=" + values + "&";
        }
    }
    window.history.replaceState('', '', newUrl.slice(0, -1));
}

function getCookie(cname) {
    var name = cname + "=";
    var cArr = window.document.cookie.split(';');
    for (var i = 0; i < cArr.length; i++) {
        var c = cArr[i].trim();
        if (c.indexOf(name) === 0)
            return c.substring(name.length, c.length);
    }
    return "";
}

function deleteCookie(cname) {
    document.cookie = cname + '=;max-age=0;;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;';
}